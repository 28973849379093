<template>
  <span class="inline-flex justify-center font-semibold items-center py-2 px-4 border border-transparent text-sm md:text-base rounded-md text-white bg-big-stone hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">
    <span v-if="icon" class="material-icons mr-2">{{ icon }}</span>
    <span>{{ title }}</span>
  </span>
</template>

<script>

export default {
  props: ['icon', 'title'],
}
</script>