export default {
  humanize(cronExpression) {
    if (!cronExpression) {
      return ''
    }

    const ordinal = (n) => {
      return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '')
    }
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    let [,,dayOfMonth,,dayOfWeek] = cronExpression.split(' ')
    let str = ''

    if (dayOfWeek !== '*') {
      const weekDays = []
      for (let d of dayOfWeek.split(',')) {
        weekDays.push(daysOfWeek[d])
      }
      str = `every ${weekDays.join(', ')}`
    }
    else if (dayOfMonth.substring(0, 2) === '*/') {
      str = `every ${ordinal(dayOfMonth.substring(2))} day of month`
    }
    else if (dayOfMonth.substring(0, 1) !== '*') {
      const dayList = dayOfMonth.split(',').sort((a, b) => parseInt(a) - parseInt(b)).map(d => ordinal(d)).join(', ')
      str = `every ${dayList} of month`
    }
    else {
      str = 'every day'
    }

    return str
  },
  formatToCron(form) {
    let error = null
    let value = '* * * * *'
    if (form.frequency === 'daily') {
      if (form.dailyRepeat > 1) {
        // on every {dailyRepeat}th day-of-month
        value = `* * */${form.dailyRepeat} * *`
      }
    }
    else if (form.frequency === 'weekly') {
      let selectedDays
      if (form.weeklyDays.length === 0) {
        error = 'At least one day must be selected'
      }
      // else if (form.weeklyDays.length === 7) {
      //   selectedDays = '*'
      // }
      else {
        selectedDays =  form.weeklyDays.join(',')
      }
      value = `* * * * ${selectedDays}`
    }
    else if (form.frequency === 'monthly') {
      let selectedDays
      if (form.monthlyDays.length === 0) {
        error = 'At least one day must be selected'
      }
      // else if (form.monthlyDays.length === 31) {
      //   selectedDays = '*'
      // }
      else {
        selectedDays =  form.monthlyDays.join(',')
      }
      value = `* * ${selectedDays} * *`
    }
    return { error, value }
  },
  formatFromCron(cronExpression) {
    let [,,dayOfMonth,,dayOfWeek] = cronExpression.split(' ')

    const form = {
      dailyRepeat: 1,
      weeklyDays: [0, 1, 2, 3, 4, 5, 6],
      monthlyDays: [],
    }
    if (dayOfWeek !== '*') {
      form.frequency = 'weekly'
      form.weeklyDays = dayOfWeek.split(',')
    }
    else if (dayOfMonth.substring(0, 2) === '*/') {
      form.frequency = 'daily'
      form.dailyRepeat = parseInt(dayOfMonth.substring(2))
    }
    else if (dayOfMonth.substring(0, 1) !== '*') {
      form.frequency = 'monthly'
      form.monthlyDays = dayOfMonth.split(',')
    }
    else {
      form.frequency = 'daily'
      form.dailyRepeat = 1
    }

    return form
  },
}