<template>
  <div>
    <div class="p-4 xl:p-6 relative">
      <div v-if="state.isLoadingMachines" class="absolute inset-0 flex justify-center items-center bg-white">
        <icon-loading class="w-6 h-6 text-blumine" />
      </div>
      <div class="flex flex-wrap md:flex-nowrap md:space-x-4">
        <div class="w-full md:w-1/2">
          <div class="p-2 md:p-4">
            <h3 class="text-sm font-bold uppercase text-big-stone border-b border-big-stone pb-2 mb-4 border-opacity-25">Machine List</h3>
            <div class="flex items-center space-x-2">
              <input type="search" v-model="state.unAssignedMachinesQuery" placeholder="Search..." class="border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out flex-1">
              <button @click="assignAllMachines" class="font-semibold text-sm px-2 py-2 rounded-lg bg-big-stone text-white hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out" type="button">
                <span v-if="state.unAssignedMachinesQuery.length === 0">Assign all</span>
                <span v-else>Assign filtered</span>
              </button>
            </div>
            <div class="mt-4 px-2">
              <div v-for="machine in unAssignedMachines" :key="machine.location_id">
                <label>
                  <input type="checkbox" v-model="state.selectedMachines" :value="machine.location_id" class="rounded">
                  <span class="ml-2">{{ machine.name }}</span>

                  <span class="text-sm block ml-6 md:inline md:ml-2">
                    <span v-if="machine.owner" class="text-gray-600">{{ machine.owner }}</span>
                    <span v-else class="text-gray-400">(No Billing Responsibility)</span>
                  </span>

                </label>
              </div>
              <div v-if="unAssignedMachines.length === 0">
                All Machines Assigned
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 mt-4 md:mt-0">
          <div class="bg-gray-50 p-2 md:p-4 rounded-md">
            <h3 class="text-sm font-bold uppercase text-big-stone border-b border-big-stone pb-2 mb-4 border-opacity-25">Assigned</h3>
            <div class="flex items-center space-x-2">
              <input type="search" v-model="state.assignedMachinesQuery" placeholder="Search..." class="border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out flex-1">
              <button @click="unAssignAllMachines" class="font-semibold text-sm px-2 py-2 rounded-lg bg-big-stone text-white hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out" type="button">
                <span v-if="state.assignedMachinesQuery.length === 0">Un-Assign all</span>
                <span v-else>Un-Assign filtered</span>
              </button>
            </div>
            <div class="mt-4 px-2">
              <div
                v-for="machine in assignedMachines"
                :key="machine.location_id"
                class="flex justify-between"
              >
                <label>
                  <input type="checkbox" v-model="state.selectedMachines" :value="machine.location_id" class="rounded text-blumine">
                  <span class="ml-2">{{ machine.name }}</span>
                  <span v-if="machine.owner" class="text-sm text-gray-600 block ml-6 md:inline md:ml-2">{{ machine.owner }}</span>
                </label>
                <label v-if="ownerManagement && (!machine.owner || machine.owner === state.user.email)">
                  <input type="checkbox" v-model="state.ownedMachines" :value="machine.location_id" class="rounded text-blue-400">
                  <span class="ml-2">Owner</span>
                </label>
              </div>
              <div v-if="assignedMachines.length === 0">
                No Assigned Machines
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import { highlightInString, sortAlpha } from '@/helpers/utils.js'
import { IconLoading } from '@/components/icons'

export default {
  components: {
    IconLoading,
  },

  props: {
    machines: {},
    modelValue: {
      type: Array,
      default: () => ([])
    }
  },

  setup(props, { emit }) {
    // TODO: ? make one universal component so can be reused for Users.Show
    // threre is already reports/MachineSelector, merge
    const ownerManagement = false

    const state = reactive({
      user: {},
      machines: props.machines,
      hasError: false,
      errorMsg: '',
      // isLoading: true,
      selectedMachines: props.modelValue,
      ownedMachines: [],
      assignedMachinesQuery: '',
      unAssignedMachinesQuery: '',
    })

    const updateValue = (value) => {
      emit('update:modelValue', value)
    }

    const assignAllMachines = () => {
      if (state.unAssignedMachinesQuery.length > 0) {
        state.selectedMachines = state.selectedMachines.concat(
          unAssignedMachines.value.map((machine) => machine.location_id)
        )
      }
      else {
        state.selectedMachines = state.machines.map((machine) => machine.location_id)
      }
    }

    const unAssignAllMachines = () => {
      if (state.assignedMachinesQuery.length > 0) {
        const idsToRemove = assignedMachines.value.map((machine) => machine.location_id)
        state.selectedMachines = state.selectedMachines.filter((machineId) => {
          return !idsToRemove.includes(machineId)
        })
      }
      else {
        state.selectedMachines = []
      }
    }

    const assignedMachines = computed(() => {
      const machines = state.machines.filter((machine) => {
        return state.selectedMachines.includes(machine.location_id)
          && (
            machine.name.toLowerCase().includes(state.assignedMachinesQuery.toLowerCase())
            || machine.owner?.toLowerCase().includes(state.assignedMachinesQuery.toLowerCase())
          )
      })

      return sortAlpha(machines, 'name')
    })

    const unAssignedMachines = computed(() => {
      const machines = state.machines.filter((machine) => {
        return ! state.selectedMachines.includes(machine.location_id)
          && (
            machine.name.toLowerCase().includes(state.unAssignedMachinesQuery.toLowerCase())
            || machine.owner?.toLowerCase().includes(state.unAssignedMachinesQuery.toLowerCase())
          )
      })

      return sortAlpha(machines, 'name')
    })

    watch(
      () => [state.selectedMachines],
      () => {
        updateValue(state.selectedMachines)
      }
    )

    watch(
      () => [props.modelValue],
      () => {
        state.selectedMachines = props.modelValue
      }
    )

    watch(
      () => props.machines,
      () => {
        return state.machines = props.machines
      }
    )

    // if (ownerManagement) {
    //   watch(
    //     () => [state.user, state.machines],
    //     () => {
    //       if (state.user && state.machines.length > 0) {
    //         state.ownedMachines = state.machines
    //           .filter(machine => machine.owner === state.user.email)
    //           .map(machine => machine.location_id)
    //       }  
    //     }
    //   )
    // }

    return {
      ownerManagement,
      state,
      highlightInString,
      assignedMachines,
      unAssignedMachines,
      assignAllMachines,
      unAssignAllMachines,
    }
  }
}
</script>
