<template>
  <nav-secondary :routes="routes" />
</template>

<script>
import { computed } from 'vue'
import NavSecondary from '@/components/NavSecondary.vue'
import { IconMachines, IconSchedules } from '@/components/icons/secondary'
export default {
  components: {
    NavSecondary
  },
  setup() {
    const routes = computed(() => {
      return [
        {
          label: 'Schedules',
          to: { name: 'SchedulerSchedulesIndex' },
          desc: 'Create and Edit schedules',
          icon: IconSchedules,
        },
        {
          label: 'Machines',
          to: { name: 'SchedulerMachinesIndex' },
          desc: 'View and Control icemaker',
          icon: IconMachines,
        }
      ]
    })
    return {
      routes,
    }
  }
}
</script>
