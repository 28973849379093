<template>
  <div>
    <label :for="name" :class="{ 'sr-only': !label, 'block text-sm font-semibold text-blumine': label }">
      {{ label }}
    </label>
    <div class="border-bigstone border-opacity-25">
      <input
        :id="name"
        type="time"
        :required="required"
        :readonly="isLoading"
        :disabled="disabled"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="block w-full border-gray-300 rounded-md text-big-stone placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out mt-1"
      >
    </div>
    <error v-if="error">{{ error }}</error>
  </div>
</template>

<script>
import Error from '@/components/form/Error.vue'

export default {
  components: {
    Error
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    modelValue: {},
    error: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  }
}
</script>
