<template>
  <div v-if="isLoading" class="absolute inset-0 flex justify-center items-center bg-white">
    <icon-loading class="w-6 h-6 text-blumine" />
  </div>

  <form v-else @submit.prevent="create">

    <!-- SETTINGS -->
    <div class="bg-white shadow-sm rounded-md">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">schedule</span>
          <h2 class="text-lg font-bold ml-1">{{ editMode ? 'Update Schedule' : 'Add Schedule' }}</h2>
        </div>
      </div>

      <div class="relative">
        <errors :errors="state.errors" class="mb-4" />

        <!-- BASIC -->
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 xl:space-x-6 p-4 xl:p-6">
          <div class="w-full md:w-1/3 flex flex-col">
            <text-input label="Name" v-model="state.form.name" :required="true" :disabled="cannotEditField('name')" />
          </div>

          <div class="w-full md:w-1/3 flex flex-col">
            <date-picker
              label="Active From"
              v-model="state.form.active_from"
              :required="true"
              :disabled="cannotEditField('active_from')"
              :disable-year="true"
              date-format="m-d"
              alt-format="M d."
            />
          </div>

          <div class="w-full md:w-1/3 flex flex-col">
            <date-picker
              label="Active Till"
              v-model="state.form.active_till"
              :required="true"
              :disabled="cannotEditField('active_till')"
              :disable-year="true"
              date-format="m-d"
              alt-format="M d."
            />
          </div>
        </div>
        <!-- /BASIC -->

        <!-- SCHEDULE TIMES -->
        <div class="w-full p-2 xl:p-3 ">
          <div v-for="(schedule_time, index) in state.form.schedule_times" :key="index" class="p-2 xl:p-3 bg-gray-100">
            <div class="flex space-x-2">
              <div class="w-1/3">
                <time-picker label="Stop Ice Machine At" v-model="schedule_time.stop" />
              </div>
              <div class="w-1/3">
                <time-picker label="Start Ice Machine At" v-model="schedule_time.start" />
              </div>
              <div class="w-1/3 flex items-center mt-6">
                <button v-if="index > 0" @click="removeScheduleTimes(index)" type="button">
                  Remove
                </button>
              </div>
            </div>
            <div v-if="isScheduleTimeOverlap(schedule_time, index)">
              <strong class="text-red-500">Overlap - This time range will be ignored</strong>
            </div>
          </div>

          <div class="mt-2">
            <button @click="addScheduleTimes" type="button">
              <add-button-inner title="Add Schedule" />
            </button>
          </div>

          <div class="my-2">
            <em class="text-sm">Every time range is in timezone of selected Machines.</em>
          </div>
        </div>
        <!-- /SCHEDULE TIMES -->
      </div>
    </div>
    <!-- /SETTINGS -->

    <!-- ICE MAKERS BOX -->
    <div class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">select_all</span>
          <h2 class="text-lg font-bold ml-1">Ice Makers</h2>
        </div>
      </div>

      <div class="relative">
        <!-- ICE MAKERS -->
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 xl:space-x-6 p-4 xl:p-6">

          <div class="w-full md:w-1/2 flex flex-col space-y-2">
            <label class="text-sm font-semibold text-blumine">
              <input type="checkbox" v-model="state.form.icemaker1_enabled" :value="true" class="rounded text-blumine">
              <span class="ml-2">Ice Maker 1</span>
            </label>
            <label class="text-sm font-semibold text-blumine">
              <input type="checkbox" v-model="state.form.icemaker2_enabled" :value="true" class="rounded text-blumine">
              <span class="ml-2">Ice Maker 2</span>
            </label>
          </div>
          <div class="w-full md:w-1/2 flex flex-col space-y-2">
          </div>
        </div>
        <!-- / ICE MAKERS -->
      </div>
    </div>
    <!-- /ICE MAKERS BOX -->

    <!-- ONE MACHINE -->
    <div v-if="machine" class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">mode_standby</span>
          <h2 class="text-lg font-bold ml-1">Machine</h2>
        </div>
      </div>

      <div class="relative p-6">
        {{ machine.name }} ({{ machine.location_id }})
      </div>
    </div>
    <!-- /ONE MACHINE -->

    <!-- MACHINE SELECTOR -->
    <div v-if="selectorMachines" class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">swap_horiz</span>
          <h2 class="text-lg font-bold ml-1">Select Machines</h2>
        </div>
      </div>

      <div class="relative pb-3">
        <machine-selector :machines="selectorMachines" v-model="state.form.machines" />
      </div>
    </div>
    <!-- /MACHINE SELECTOR -->

    <!-- RECURRENCE SETUP -->
    <div class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">repeat</span>
          <h2 class="text-lg font-bold ml-1">Recurrence Setup</h2>
        </div>
      </div>

      <div class="relative pb-3">
        <recurrence-setup v-model="state.form.cron" />
      </div>
    </div>
    <!-- /RECURRENCE SETUP -->

    <div class="pt-6 xl:pt-8: text-right">
      <submit-button :is-loading="state.isSaving">{{ editMode ? 'Update' : 'Create' }}</submit-button>
    </div> 
  </form>
</template>

<script>
import { reactive, watch } from 'vue'
import notification from '@/services/notifications.js'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import cron from '@/helpers/cron.js'
import { sneakCaseToReadable, uuidv4 } from '@/helpers/utils'
import TextInput from '@/components/form/Text.vue'
// import SelectBox from '@/components/form/Select.vue'
import TimePicker from '@/components/form/TimePicker.vue'
import DatePicker from '@/components/form/DatePicker.vue'
import Errors from '@/components/form/Errors.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import { IconLoading } from '@/components/icons'
import AddButtonInner from '@/components/buttons/AddButtonInner.vue'
import RecurrenceSetup from '@/components/schedules/RecurrenceSetup.vue'
import MachineSelector from '@/components/schedules/MachineSelector.vue'

export default {
  name: 'schedules-schedule-form',

  components: {
    TextInput,
    SubmitButton,
    Errors,
    // SelectBox,
    IconLoading,
    TimePicker,
    DatePicker,
    AddButtonInner,
    RecurrenceSetup,
    MachineSelector,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    initialMachines: {},
    selectorMachines: {},
    form: {
      type: Object,
      default: () => ({
        schedule_id: uuidv4(),
        machines: [],
        schedule_times: [
          {}
          // { stop: '19:00', start: '01:00' },
          // { stop: '20:00', start: '21:00' }
        ],
        cron: '* * * * *'
      }),
    },
    permissions: {
      type: Object,
      default: () => ({
        readonly_fields: []
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['created'],

  setup(props, { emit }) {
    const state = reactive({
      form: props.form,
      // initialMachines: [],
      errors: [],
      isSaving: false,
      users: [],
    })

    const cannotEditField = (field) => props.permissions.readonly_fields.includes(field)

    const addScheduleTimes = () => {
      state.form.schedule_times.push({})
    }

    const removeScheduleTimes = (index) => {
      state.form.schedule_times.splice(index, 1)
    }

    const create = async () => {
      const errors = []
      if (state.form.machines.length === 0) {
        errors.push('Select at least one machine')
      }
      if (state.form.cron.length === 0) {
        errors.push('Recurrence Setup is missing')
      }
      if (!state.form.icemaker1_enabled && !state.form.icemaker2_enabled) {
        errors.push('Select at least one Ice Maker')
      }
      if (state.form.schedule_times.filter((times, index) => isScheduleTimeValid(times, index)).length === 0) {
        errors.push('Enter at least one valid Time Schedule')
      }
      if (errors.length > 0) {
        notification.error('Required fields', errors.join('<br>'))
        return
      }

      const form = {
        ...state.form,
        schedule_times: state.form.schedule_times
          .filter((times, index) => isScheduleTimeValid(times, index))
          .map((times) => {
            return {
              stop: date.timeFormat(times.stop).forApi(),
              start: date.timeFormat(times.start).forApi(),
            }
          })
      }

      state.errors = []
      state.isSaving = true

      try {
        if (props.editMode) {
          const diffToDelete = state.initialMachines.filter(x => !state.form.machines.includes(x))
          await http.put(`machine/schedule`, {
            replace_existing_schedule_id: form.schedule_id,
            schedule: {
              ...form,
              schedule_id: uuidv4()
            },
            assign_to_machines: form.machines,
            delete_from_machines: diffToDelete
          })
        }
        else {
          await http.post(`machine/schedule`, {
            schedule: form,
            assign_to_machines: form.machines,
          })
        }

        if (props.editMode) {
          notification.success('Success', 'Schedule has been updated')
        }
        else {
          notification.success('Success', 'Schedule has been created')
        }
        emit('created', form)
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const convertTimeToNumber = (time) => {
      const hours = Number(time.split(':')[0])
      const minutes = Number(time.split(':')[1]) / 60
      return hours + minutes
    }

    const overlapTwoScheduleTimes = (primarySchedule, secondarySchedule) => {
      let a = convertTimeToNumber(primarySchedule.stop)
      let b = convertTimeToNumber(primarySchedule.start)
      if (a > b) b += 24
      let t0 = [a, b]

      let c = convertTimeToNumber(secondarySchedule.stop)
      let d = convertTimeToNumber(secondarySchedule.start)
      if (c > d) d += 24
      let t1 = [c, d]

      const olap = (t0, t1) => {
          return (t0[0] < t1[1] && t0[1] > t1[0])
      }
      return olap(t0, t1) || olap(t1, t0)
    }

    // TODO: fix this overlap
    // console.log('this', overlapTwoScheduleTimes(
    //   { stop: '19:00', start: '03:00' },
    //   { stop: '02:30', start: '06:00' }
    // ), true)

    const isScheduleTimeOverlap = (schedule, index) => {
      if (!schedule.stop || !schedule.start) {
        return false
      }
      for (let i = 0; i < index; i++) {
        const otherSchedule = state.form.schedule_times[i]
        if (overlapTwoScheduleTimes(otherSchedule, schedule)) {
          return true
        }
      }
      return false
    }

    const isScheduleTimeValid = (schedule, index) => {
      if (!schedule.stop || !schedule.start) {
        return false
      }
      return !isScheduleTimeOverlap(schedule, index)
    }

    watch(
      () => props.form,
      (form) => {
        if (form.machines) {
          state.initialMachines = form.machines
        }
        return state.form = {
          ...form
        }
      }
    )

    return {
      cron,
      addScheduleTimes,
      removeScheduleTimes,
      create,
      sneakCaseToReadable,
      state,
      cannotEditField,
      isScheduleTimeValid,
      isScheduleTimeOverlap,
    }
  }
}
</script>
