<template>
  <div class="m-4">
    <select-box label="Frequency" v-model="state.form.frequency">
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>
      <option value="monthly">Monthly</option>
    </select-box>

    <div class="mt-4">
      <div v-if="state.form.frequency === 'daily'">
        <text-input label="Repeat every day of month" v-model="state.form.dailyRepeat" maxlength="2" />
      </div>
      <div v-else-if="state.form.frequency === 'weekly'">
        <label
          v-for="(day, index) in weekDays" :key="index"
          class="text-sm font-semibold text-blumine mr-3"
        >
            <input type="checkbox" v-model="state.form.weeklyDays" :value="index" class="rounded text-blumine">
            <span class="ml-1">{{ day }}</span>
        </label>
      </div>
      <div v-else-if="state.form.frequency === 'monthly'">
        <label
          v-for="(day, index) in monthDays" :key="index"
          class="text-sm font-semibold text-blumine mr-3 whitespace-nowrap"
        >
            <input type="checkbox" v-model="state.form.monthlyDays" :value="day" class="rounded text-blumine">
            <span class="ml-1">{{ day }}</span>
        </label>
      </div>

      <div v-if="state.cronFormatError" class="text-red-500">
        {{ state.cronFormatError }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import cron from '@/helpers/cron.js'
import TextInput from '@/components/form/Text.vue'
import SelectBox from '@/components/form/Select.vue'

export default {
  components: {
    SelectBox,
    TextInput,
  },
  props: {
    modelValue: {
      default: "* * * * *"
    },
  },
  setup(props, { emit }) {
    const daysInMonth = () => Array.from({length: 31}, (_, i) => i + 1)
    const monthDays = daysInMonth()
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const state = reactive({
      cronFormatError: null,
      form: cron.formatFromCron(props.modelValue)
    })

    const updateValue = (value) => {
      emit('update:modelValue', value)
    }

    const toCron = () => {
      const { error, value } = cron.formatToCron(state.form)

      updateValue(error ? '' : value)
      state.cronFormatError = error
    }

    watch(
      () => [
        state.form.frequency,
        state.form.dailyRepeat,
        state.form.weeklyDays,
        state.form.monthlyDays,
      ],
      () => {
        toCron()
      }
    )

    watch(
      () => props.modelValue,
      () => {
        state.form = cron.formatFromCron(props.modelValue)
      }
    )

    // toCron()

    return {
      state,
      weekDays,
      monthDays,
    }
  }
}
</script>
