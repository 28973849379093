<template>
  <text-input :name="inputName" :label="label" :model-value="modelValue" />
</template>

<script>
import { watch, onBeforeUnmount, onMounted, computed } from 'vue'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { directive } from 'vue3-click-away'
import TextInput from '@/components/form/Text'

export default {
  name: 'date-picker',

  directives: {
    ClickAway: directive,
  },

  components: {
    TextInput,
  },

  props: {
    'model-value': {},
    submit: {},
    label: {
      type: String,
      required: false,
      default: '',
    },
    dateFormat: {
      type: String,
      default: 'Y-m-d',
    },
    altFormat: {
      type: String,
      default: 'm/d/Y',
    },
    disableYear: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    let picker = null

    const inputName = computed(() => {
      return 'picker_' + props.label.replace(' ', '')
    })

    onMounted(() => {
      picker = flatpickr(document.getElementById(inputName.value), {
        enableTime: false,
        altInput: true,
        dateFormat: props.dateFormat,
        altFormat: props.altFormat,
        onClose(selectedDateTime, dateStr) {
          const result = dateStr
          emit('change', result)
          emit('update:modelValue', result)
        },
      })
      if (props.disableYear) {
        picker.calendarContainer.querySelector('.cur-year').style.display = 'none'
      }
    })

    onBeforeUnmount(() => picker ? picker.destroy() : null)

    watch(
      () => props.modelValue,
      () => {
        if (picker) {
          picker.setDate(props.modelValue)
        }
      }
    )

    return {
      inputName
    }
  }
}
</script>
