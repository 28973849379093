<template>
  <div>
    <label :for="name" :class="{ 'sr-only': !label, 'block text-sm font-semibold text-blumine mb-1': label }">
      {{ label }} <span v-if="required" class="text-shakespear">*</span>
    </label>
    <div class="border-big-stone border-opacity-25">
      <select
        :id="name"
        :required="required"
        :readonly="isLoading"
        :disabled="disabled"
        :value="modelValue"
        :placeholder="placeholder"
        @change="$emit('update:modelValue', $event.target.value); $emit('change', $event.target.value)"
        :class="{ 'bg-gray-50': disabled }"
        class="block w-full border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out"
      ><slot /></select>
    </div>
    <error v-if="error">{{ error }}</error>
  </div>
</template>

<script>
import Error from '@/components/form/Error.vue'

export default {
  components: {
    Error
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    modelValue: {},
    error: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
  },

  emits: ['update:modelValue', 'change']
}
</script>
