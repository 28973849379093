<template>
  <!-- Mobile Secondary sidebar -->
  <nav aria-label="Sections" class="bg-white border-gray-200 xl:hidden px-2 pb-2 pt-2">
    <router-link
      v-for="(route, index) in routes"
      :key="index"
      :to="route.to"
      :active-class="'bg-sail bg-opacity-20'"
      class="hover:bg-sail hover:bg-opacity-20 flex p-2 border-b border-gray-200"
    >
      <component v-if="route.icon" :is="route.icon" class="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400" />
      <div class="ml-3 text-sm">
        <p class="font-semibold text-big-stone">{{ route.label }}</p>
      </div>
    </router-link>
  </nav>

  <!-- Desktop Secondary sidebar -->
  <nav aria-label="Sections" class="hidden flex-shrink-0 xl:w-60 xxl:w-72 hd:w-96 bg-white border-r border-gray-200 xl:flex xl:flex-col">
    <div class="flex-1 min-h-0 overflow-y-auto">

      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :to="route.to"
        :active-class="'bg-sail bg-opacity-20'"
        class="hover:bg-sail hover:bg-opacity-20 flex p-6 border-b border-gray-200"
      >
        <component v-if="route.icon" :is="route.icon" class="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400" />

        <div class="ml-3 text-sm">
          <p class="font-semibold text-big-stone">{{ route.label }}</p>
          <p v-if="route.desc" class="mt-1 text-gray-500 line-clamp-2">
            {{ route.desc }}
          </p>
        </div>
      </router-link>

    </div>
  </nav>
</template>

<script>
export default {
  props: {
    routes: {
      required: true
    }
  }
}
</script>
